import React from 'react';
import { Box, Container, Text, Link, Heading } from '@chakra-ui/react';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <Box color="white" py={10} background="#141414b5">
      <Container maxW="container.lg">
        <Text position="relative" top="1.5vw" left="-19vw" width="36vw" text-align="left">&copy; {currentYear} NewChapter Roleplay – Alle Rechte vorbehalten.<br></br>
          NewChapter Roleplay hat keine offizielle Verbindung zu Rockstar Games, Take-Two Interactive Software oder alt:V Multiplayer. Hosted by <Link href="https://playverse-hosting.com" color="white" _hover={{ textDecoration: 'underline' }} isExternal>Playverse-Hosting.</Link>
        </Text>
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
          <Box position="relative" top="-3.75vw" left="29vw" width="12vw" text-align="left">
            <Heading as="h2" fontSize="1vw" mb={2}>
              Rechtliches
            </Heading>
            <Link href="/impressum" color="white" _hover={{ textDecoration: 'underline' }}>
              Impressum
            </Link>
          </Box>
          <Box position="relative" top="-3.75vw" left="29vw" width="12vw" text-align="left">
            <Heading as="h2" fontSize="1vw" mb={2}>
              Links
            </Heading>
            <Box display="flex" flexDir="column">
              <Link href="https://discord.gg/7DZdRvd9WU" display="inline-flex" alignItems="center" color="white" _hover={{ textDecoration: 'underline' }}>
                Discord
              </Link>
              <Link href="ts3server://ts.newchapter-roleplay.de" display="inline-flex" alignItems="center" color="white" _hover={{ textDecoration: 'underline' }}>
                Teamspeak
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;