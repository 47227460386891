import React, { useState, useEffect } from 'react';
import { Button, Flex, Box, Heading, Text, Container, Tooltip, Grid, Input, useToast } from '@chakra-ui/react';
import { useAuth } from '../provider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faUser, faMoneyBill, faHeartbeat, faPhoneAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

function Profile() {
    const { isLoggedIn, username, userId, setIsLoggedIn, setUsername, setUserId } = useAuth();
    const [newPassword, setNewPassword] = useState('');
    const [characterName, setCharacterName] = useState('');
    const [charPassword, setCharacterPasswort] = useState('');
    const [charConfirmPassword, setCharConfirmPassword] = useState('');
    const [characterData, setCharacterData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const toast = useToast();
    const apiUrl = 'https://newchapter-roleplay.de/api/api-proxy.php';

    useEffect(() => {
        if (isLoggedIn) {
            setCharacterData(null)
            localStorage.removeItem('characterData');
            fetchCharacterData();
        } else {
            navigate('/');
        }
    }, [isLoggedIn]);

    const fetchCharacterData = async () => {
        try {
            const data = {
                apiaction: 'getCharacterData',
                action: 'getCharacterData',
                forumId: userId
            };

        
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const responseText = await response.text();
                const result = await JSON.parse(responseText);
                if (result && result.accountId) {
                    setCharacterData(result);
                } else {
                    setCharacterData(null);
                }
            } else {
                setCharacterData(null);
            }
        } catch (error) {
            setCharacterData(null);
        }
        setLoading(false);
    };

    const handleChangePassword = async () => {
        if (newPassword === "") {
            toast({
                title: 'Fehler beim Ändern des Passworts.',
                description: 'Bitte gebe dein Passwort in den Input ein.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setLoading(true);
        try {

            const data = {
                apiaction: 'changePassword',
                action: 'changePassword',
                forumId: userId,
                password: newPassword
            };

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const responseText = await response.text();
                const result = await JSON.parse(responseText);
                if (!result.message.includes("Passwort erfolgreich aktualisiert.")) {
                    toast({
                        title: 'Fehler beim Ändern des Passworts.',
                        description: result.message,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                    return;
                }
                toast({
                    title: 'Passwort erfolgreich geändert.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Fehler beim Ändern des Passworts.',
                    description: 'Bitte versuche es später noch einmal.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Fehler beim Ändern des Passworts.',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
        setLoading(false);
    };

    const handleCreateCharacter = async () => {
        if (!characterName) {
            toast({
                title: 'Fehler',
                description: 'Vor_Nachname ist erforderlich.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (characterName.indexOf(' ') !== -1 || characterName.indexOf('_') === -1) {
            toast({
                title: 'Fehler',
                description: 'Richtiges Format nutzen (Vor_Nachname).',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (charPassword !== charConfirmPassword) {
            toast({
                title: 'Passwortfehler',
                description: 'Die Passwörter stimmen nicht überein.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setLoading(true);

        try {
            const data = {
                apiaction: 'createCharacter',
                action: 'createCharacter',
                forumId: userId,
                name: characterName,
                password: charPassword
            };

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                toast({
                    title: 'Charakter erstellt',
                    description: 'Dein Charakter wurde erfolgreich erstellt.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Fehler',
                    description: 'Es gab einen Fehler bei der Anfrage.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            
        }
        setLoading(false);
        fetchCharacterData();
    };

    const HandleConnectClick = () => {
        window.location.href = 'altv://connect/5.9.115.6:8589';
    }

    const HandleLogout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
        setUsername('');
        setUserId('');
        navigate('/');
    }

    const moneyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Container maxW="container.md" mt={12}>
            <Box p={5}>
                <Box marginBottom="2vw" width="100%">
                    <Flex justify="space-between" align="center">
                        <Heading as="h3" size="lg" mb={3}>
                            Hey {username}! 👋
                        </Heading>
                        <Box>
                            <Tooltip label="Verbinde dich auf den alt:V Server" aria-label="Rocket Tooltip">
                                <Button onClick={HandleConnectClick} bg="#0f0f0f" padding="2vh" color="white" borderRadius="1vh" border="1px solid hsla(0, 0%, 100%, .1)" marginRight="4vh" mr={3}><FontAwesomeIcon icon={faRocket} /></Button>
                            </Tooltip>
                            <Tooltip label="Logge dich aus" aria-label="Rocket Tooltip">
                                <Button onClick={HandleLogout} bg="#0f0f0f" padding="2vh" color="white" borderRadius="1vh" border="1px solid hsla(0, 0%, 100%, .1)" marginRight="4vh"><FontAwesomeIcon icon={faSignOutAlt} /></Button>
                            </Tooltip>
                        </Box>
                    </Flex>
                </Box>
                <Heading as="h3" size="md" mb={3}>Deine Charaktere</Heading>
                {loading ? (
                    <Text>Loading...</Text>
                ) : characterData ? (
                    <Box bg="#0f0f0f" padding="20px" borderRadius="12px" border="1px solid hsla(0, 0%, 100%, .1)" minHeight="110px" marginRight="4vh">
                        <Heading as="h3" size="md">{characterData.name.replace('_', ' ')}</Heading>
                        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4} marginBottom="2vw">
                            <Box display="flex" alignItems="center">
                                <FontAwesomeIcon icon={faUser} style={{ marginRight: '8px' }} />
                                <Text fontWeight="bold">Player ID:</Text>
                            </Box>
                            <Text textAlign="right" ml={300}>{characterData.accountId}</Text>
                            <Box display="flex" alignItems="center">
                                <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: '8px' }} />
                                <Text fontWeight="bold">Payday:</Text>
                            </Box>
                            <Text textAlign="right" ml={300}>{characterData.payday}</Text>
                            <Box display="flex" alignItems="center">
                                <FontAwesomeIcon icon={faHeartbeat} style={{ marginRight: '8px' }} />
                                <Text fontWeight="bold">HP:</Text>
                            </Box>
                            <Text textAlign="right" ml={300}>{characterData.hp}</Text>
                            <Box display="flex" alignItems="center">
                                <FontAwesomeIcon icon={faMoneyBill} style={{ marginRight: '8px' }} />
                                <Text fontWeight="bold">Money:</Text>
                            </Box>
                            <Text textAlign="right" ml={300}>{moneyFormatter.format(characterData.money)}</Text>
                            <Box display="flex" alignItems="center">
                                <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: '8px' }} />
                                <Text fontWeight="bold">Phone:</Text>
                            </Box>
                            <Text textAlign="right" ml={300}>{characterData.phone || 'No phone number'}</Text>
                        </Grid>
                        <Heading textAlign="center" as="h3" size="md" mb={3}>Ändere dein Passwort:</Heading>
                        <Input
                            type="password"
                            placeholder="Neues Passwort"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            mb={3}
                            backgroundColor="transparent"
                            color="white"
                        />
                        <Button
                            onClick={handleChangePassword}
                            isLoading={loading}
                            loadingText="Passwort wird geändert..."
                            background="#3399ff"
                            color="white"
                            width="100%"
                        >
                            Passwort ändern
                        </Button>
                    </Box>
                ) : (
                    <Box p={5} bg="#0f0f0f" padding="20px" borderRadius="12px" border="1px solid hsla(0, 0%, 100%, .1)" minHeight="110px" marginRight="4vh">
                        <Text mb={3}>Erstelle einen neuen Charakter</Text>
                        <Input
                            type="text"
                            placeholder="Vor_Nachname"
                            color="white"
                            value={characterName}
                            onChange={(e) => setCharacterName(e.target.value)}
                            mb={3}
                            backgroundColor="transparent"
                        />
                        <Input
                            type="password"
                            placeholder="Passwort"
                            color="black"
                            value={charPassword}
                            onChange={(e) => setCharacterPasswort(e.target.value)}
                            mb={3}
                            backgroundColor="transparent"
                        />
                        <Input
                            type="password"
                            color="black"
                            placeholder="Passwort wiederholen"
                            value={charConfirmPassword}
                            onChange={(e) => setCharConfirmPassword(e.target.value)}
                            mb={3}
                            backgroundColor="transparent"
                        />
                        <Button
                            onClick={handleCreateCharacter}
                            isLoading={loading}
                            loadingText="Charakter wird erstellt..."
                            background="#3399ff"
                            color="white"
                            width="100%"
                        >
                            Charakter erstellen
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
}

export default Profile;