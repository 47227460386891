import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import '../css/team.css';

function Team() {
    return (
        <Box marginBottom="3vh" marginTop="10vh" width="70vw" marginLeft="15vw">
            <Heading marginBottom="5vh" id="faq-section" as="h2" fontWeight="800" fontSize="2.6vh" textAlign="left">
                UNSER TEAM
            </Heading>
            <Box marginBottom="5vh" width="100%" bg="#0f0f0f" padding="3vh" color="white" borderRadius="1vh" border="1px solid hsla(0, 0%, 100%, .1)">
                <Box textAlign="left" marginBottom="2vw">
                    <Heading as="h2" fontSize="1.5vw" marginBottom="3.5vh">
                        HEAD OF PROJECT
                    </Heading>
                    <ul class="teamul">
                        <li>
                            <img src="https://cdn.discordapp.com/avatars/372809108845232129/a_ca1c29dbb4878f625b63a61bd69d9812.webp?size=128" />
                            <h3>1995ziig</h3>
                        </li>
                        <li>
                            <img src="https://cdn.discordapp.com/avatars/627633596206678037/6773474715514ccae19feeb5d651415a.webp?size=128" />
                            <h3>Cookie</h3>
                        </li>
                    </ul>
                </Box>
                <Box textAlign="left" marginBottom="2vw">
                    <Heading as="h2" fontSize="1.5vw" marginBottom="3.5vh">
                        HEAD DEVELOPEMENT
                    </Heading>
                    <ul class="teamul">
                        <li>
                            <img src="https://cdn.discordapp.com/avatars/280637735956643841/a_6aa24784334c9fd28253a7d4be6cf854.webp?size=80" />
                            <h3>Александар</h3>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Box>
    )
};

export default Team;