import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import HeroSection from './pages/HeroSection';
import WhatIs from './WhatIs';
import FAQSection from './pages/FAQSection';
import FeaturesSection from './pages/FeaturesSection';
import Footer from './pages/Footer';
import Profile from './pages/Profile';
import Impressum from './pages/Impressum';
import Header from './pages/Header';
import Team from './pages/Team';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import './css/App.css';

function App() {

  useEffect(() => {
    document.title = "NewChapter Roleplay";
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <WhatIs />
              <FeaturesSection />
              <FAQSection />
              <Footer />
            </>
          }
        />

        <Route path="/profile" element={
          <>
          <Header />
          <Profile />
          <Footer />
          </>
          } 
        />
        <Route path="/impressum" element={
          <>
          <Header />
          <Impressum />
          <Footer />
          </>
          } 
        />
        <Route path="/team" element={
          <>
          <Header />
          <Team />
          <Footer />
          </>
          } 
        />
      </Routes>
    </ChakraProvider>
  );
}

export default App;