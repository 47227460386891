import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';

function Impressum() {
    return (
        <Box marginBottom="3vh" marginTop="2vh" width="70vw" marginLeft="15vw">
            <Heading as="h2" fontSize="1.5vw" mb={6}>
                IMPRESSUM / DATENSCHUTZ
            </Heading>
            <Box textAlign="left" bg="#0f0f0f" padding="2vh" color="white" borderRadius="1vh" border="1px solid hsla(0, 0%, 100%, .1)" p={4}>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginBottom="1vh">1. Verantwortlicher / Impressum</Heading>
                <Heading as="h3" fontSize="2vh" fontWeight="normal">Name</Heading>
                <Text>Jonas Feldhuis</Text>
                <Heading marginTop="2vh" as="h3" fontSize="2vh" fontWeight="normal">Adresse</Heading>
                <Text>Sandwieke 16</Text>
                <Text>26835 Hesel</Text>
                <Heading marginTop="2vh" as="h3" fontSize="2vh" fontWeight="normal">E-Mail Adresse</Heading>
                <Text>Auf Anfrage</Text>
                <Heading marginTop="2vh" as="h3" fontSize="2vh" fontWeight="normal">Telefon</Heading>
                <Text>Auf Anfrage</Text>
                <Heading marginTop="2vh" as="h3" fontSize="2vh" fontWeight="normal">Zusatzinformationen</Heading>
                <Text>NewChapter Roleplay hat keine offizielle Verbindung zu Rockstar Games, Take-Two Interactive Software oder alt:V Multiplayer.</Text>
                <Heading marginTop="2vh" as="h3" fontSize="2vh" fontWeight="normal">Urheberrecht</Heading>
                <Text>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                    Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
                    Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend melden.</Text>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginTop="3vh" marginBottom="1vh">2. Verarbeitung personenbezogener Daten</Heading>
                <Heading as="h2" fontSize="1.8vh">A) Benutzername und Passwort</Heading>
                <Text>Wir speichern lediglich die von dir beim Erstellen eines Kontos angegebenen Daten:</Text>
                <Text marginTop="2vh">Benutzername</Text>
                <Text>Passwort (verschlüsselt)</Text>
                <Text>Diese Daten werden ausschließlich zum Zweck der Bereitstellung eines sicheren Zugangs zu deinem Konto gespeichert.</Text>
                <Heading marginTop="2vh" as="h2" fontSize="1.8vh">B) Keine Speicherung weiterer persönlicher Daten</Heading>
                <Text>Wir erheben und speichern keine weiteren personenbezogenen Daten wie z. B. E-Mail-Adressen.</Text>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginTop="3vh" marginBottom="1vh">3. Rechtsgrundlage der Verarbeitung</Heading>
                <Text>Die Speicherung deines Benutzernamens und Passworts erfolgt auf Grundlage von Artikel 6 Absatz 1 Buchstabe b DSGVO (Erfüllung eines Vertrags).</Text>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginTop="3vh" marginBottom="1vh">4. Sicherheit deiner Daten</Heading>
                <Text>Dein Passwort wird ausschließlich verschlüsselt gespeichert, sodass ein Zugriff durch unbefugte Dritte ausgeschlossen ist. Wir verwenden aktuelle technische und organisatorische Sicherheitsmaßnahmen, um deine Daten vor Verlust, Manipulation oder unberechtigtem Zugriff zu schützen.</Text>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginTop="3vh" marginBottom="1vh">5. Datenweitergabe</Heading>
                <Text>Eine Weitergabe deiner Daten an Dritte erfolgt nicht, es sei denn, dies ist zur Erfüllung gesetzlicher Verpflichtungen erforderlich.</Text>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginTop="3vh" marginBottom="1vh">6. Speicherdauer</Heading>
                <Text>Wir speichern deine Daten, solange dein Benutzerkonto aktiv ist. Möchtest du dein Benutzerkonto löschen, werden alle gespeicherten Daten unwiderruflich entfernt.</Text>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginTop="3vh" marginBottom="1vh">7. Deine Rechte</Heading>
                <Heading as="h2" fontSize="1.8vh">Du hast das Recht:</Heading>
                <Text>Auskunft über die von uns gespeicherten Daten zu erhalten (Art. 15 DSGVO),</Text>
                <Text>die Berichtigung unrichtiger Daten zu verlangen (Art. 16 DSGVO),</Text>
                <Text>die Löschung deiner Daten zu verlangen (Art. 17 DSGVO),</Text>
                <Text>die Einschränkung der Verarbeitung zu verlangen (Art. 18 DSGVO),</Text>
                <Text>der Verarbeitung zu widersprechen (Art. 21 DSGVO).</Text>
                <Text>Zur Wahrnehmung deiner Rechte kannst du uns über die oben genannten Kontaktdaten erreichen.</Text>
                <Heading as="h3" fontSize="2.5vh" fontWeight="normal" marginTop="3vh" marginBottom="1vh">8. Änderungen dieser Datenschutzerklärung</Heading>
                <Text>Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf anzupassen, um sie an rechtliche oder technische Änderungen anzupassen. Die aktuelle Version ist stets auf unserer Website abrufbar.</Text>
            </Box>
        </Box>
    )
};

export default Impressum;