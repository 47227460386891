import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Text, Container, Flex, Spacer, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-scroll';
import AuthModal from '../modals/AuthModal';
import Header from './Header';
import { useAuth } from '../provider/AuthProvider';
import ParticleBackground from '../js/particles';

function HeroSection() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const handlePlayClick = () => {
        if (isLoggedIn) {
            navigate('/profile');
        } else {
            onOpen();
        }
    }

    return (
        <Box
            position="relative"
            height="80vh"
            width="100%"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            color="white"
        >
            <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                overflow="hidden"
                style={{
                    backgroundImage: "url('/assets/853414.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "bottom",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <ParticleBackground />
            </Box>

            <Container zIndex={1}>
                <Box position="relative" left="-28vw" textAlign="left" mb={6} whiteSpace="nowrap">
                    <Heading as="h1" size="2xl" fontWeight="normal" mb={4}>
                        Willkommen bei <span style={{ color: '#3399ff' }}>NewChapter</span>
                    </Heading>
                    <Heading as="h2" fontSize="4xl" fontWeight="bold" mb={4}>
                        Deinem GTA V Roleplay-Server
                    </Heading>
                </Box>
                <Box position="relative" left="-28vw" display="flex" justifyContent="left" gap={6}>
                    <Link to="faq-section" smooth={true} duration={500}>
                        <Button color="white" variant="outline" size="lg" _hover={{ backgroundColor:"white", color: 'black' }}>
                            Fragen und Antworten
                        </Button>
                    </Link>
                    <Button color="white" backgroundColor="#3399ff" size="lg" onClick={handlePlayClick}>
                        Jetzt spielen!
                    </Button>
                </Box>
            </Container>
            <Header onOpen={onOpen}/>
            <AuthModal isOpen={isOpen} onClose={onClose} />
        </Box>
    );
}

export default HeroSection;