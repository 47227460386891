import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      primary: '#3399ff', // Blau von Homestate
      secondary: '#3399ff', // Türkis
      accent: '#3399ff', // Hintergrundfarbe
    },
  },
  fonts: {
    heading: '"Roboto", sans-serif',
    body: '"Open Sans", sans-serif',
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Open Sans, sans-serif',
        // backgroundColor: `rgb(18, 18, 18)`,
        backgroundColor: `#0e0e0e`,
        color: 'white',
      },
    },
  },
});

export default theme;