import React from 'react';
import { Box, Text, Heading, } from '@chakra-ui/react';

function FAQSection() {
    return (
        <Box marginBottom="3vh" marginTop="10vh" width="100%" paddingInline="10vh">
            <Heading marginBottom="5vh" id="faq-section" as="h2" fontWeight="800" fontSize="2.6vh" textAlign="left">
                ANTWORTEN AUF HÄUFIGE FRAGEN
            </Heading>
            <Box width="100%" display="flex" flexDirection="row" alignItems="center" textAlign="left">
                <Box bg="#0f0f0f" padding="20px" borderRadius="12px" border="1px solid hsla(0, 0%, 100%, .1)" minHeight="110px" marginRight="4vh">
                    <Heading as="h2" fontSize="1.8vh" mb={3}>
                        Wofür steht NewChapter?
                    </Heading>
                    <Text fontSize="1.5vh" whiteSpace="normal">
                    NewChapter ist ein lebendiger GTA 5 Roleplay Server, der eine realistische Spielerfahrung in Los Santos bietet. Mit einer engagierten Community, verschiedenen Berufen, Fraktionen und benutzerdefinierten Scripts ermöglicht der Server eine vielseitige und immersive Spielerfahrung. 
                    </Text>
                </Box>
                <Box bg="#0f0f0f" padding="20px" borderRadius="12px" border="1px solid hsla(0, 0%, 100%, .1)" minHeight="110px" marginRight="4vh">
                    <Heading as="h2" fontSize="1.8vh" mb={3}>
                        Was ist für mich als neuer Spieler wichtig?
                    </Heading>
                    <Text fontSize="1.5vh" whiteSpace="normal">
                        Unser Guide leitet dich durch alles wichtige! Diesen findest du oben in der Navigation. Gemeinsam mit den Severregeln solltest du diese vor deinem Start einmal durchlesen.
                    </Text>
                </Box>
                <Box bg="#0f0f0f" padding="20px" borderRadius="12px" border="1px solid hsla(0, 0%, 100%, .1)" minHeight="110px" marginRight="4vh">
                    <Heading as="h2" fontSize="1.8vh" mb={3}>
                        Ich habe eine Idee. Was mache ich damit?
                    </Heading>
                    <Text fontSize="1.5vh" whiteSpace="normal">
                        Auf unserem Discord-Server haben wir hierfür den Channel Verbesserungsvorschläge eingerichtet. Hier könnt ihr eure Gedanken der Community und uns vorstellen und darüber sprechen.
                    </Text>
                </Box>
            </Box>
        </Box>
    );
}

export default FAQSection;