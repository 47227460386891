import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-scroll';

function WhatIs() {
    return (
        // <Box marginBottom="3vh" marginTop="10vh" display="flex" justifyContent="center" alignItems="center" width="100%">
            <Box position="relative" width="70vw" top="2vw" left="15vw" bg="#0f0f0f" padding="2vh" borderRadius="12px" border="1px solid hsla(0, 0%, 100%, .1)" minHeight="13vh" marginRight="4vh" p={4} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Box width="19vw" marginRight="2.5vw">
                    <Heading width="22vw" as="h2" fontSize="2vw" mb={6}>
                        Was ist NewChapter?
                    </Heading>
                    <Link to="faq-section" smooth={true} duration={500}>
                        <Button color="white" variant="outline" size="lg" _hover={{ backgroundColor:"white", color: 'black' }}>
                            Weitere Fragen und Antworten
                        </Button>
                    </Link>
                </Box>
                <Box maxWidth="53vw" overflowWrap="break-word">
                    <Text fontSize="2vh" mb={6}>
                        NewChapter ist ein lebendiger GTA 5 Roleplay Server, der eine realistische Spielerfahrung in
                        Los Santos bietet.
                        Mit einer engagierten Community, verschiedenen Berufen, Fraktionen und benutzerdefinierten Scripts
                        ermöglicht der Server eine vielseitige und immersive Spielerfahrung.
                        Fairplay und Respekt stehen im Vordergrund, unterstützt durch ein aufmerksames Server-Team.
                        Trete noch heute bei und gestalte deine eigene Geschichte in der aufregenden Welt von NewChapter!
                    </Text>
                </Box>
            </Box>
        // </Box>
    );
}

export default WhatIs;