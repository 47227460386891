import { Box, Button } from '@chakra-ui/react';
import { FaUserCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { useAuth } from '../provider/AuthProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/header.css';

function Header({ onOpen }) {
    const location = useLocation();
    const { isLoggedIn, username } = useAuth();
    const navigate = useNavigate();

    const handleProfileRedirect = () => {
        if (isLoggedIn) {
            navigate('/profile');
        }
    };

    const handleLoginClick = () => {
        if (location.pathname !== "/") {
            navigate('/');
            onOpen();
        } else {
            onOpen();
        }
    };

    const handleLinkClick = (link) => {
        window.open(`https://${link}`, '_blank');
    };

    let headerClass = "";

    if (location.pathname === "/") {
        headerClass = "home-header";
    } else {
        headerClass = "default-header";
    }

    return (
        <Box className={headerClass}>
        <Button onClick={() => navigate('/')} width='6vw' height='2.5vw' borderRadius='.2vw' color='#fff' background='transparent' border='none' outline='none' fontSize='1vw' fontWeight='700' _hover={{ backgroundColor: '#1a1a1a' }}>
            Start
        </Button>
        <Button onClick={() => navigate('/team')} width='6vw' height='2.5vw' borderRadius='.2vw' color='#fff' background='transparent' border='none' outline='none' fontSize='1vw' fontWeight='700' _hover={{ backgroundColor: '#1a1a1a' }}>
            Team
        </Button>
        <Button onClick={() => handleLinkClick('wiki.newchapter-roleplay.de/docs/regelwerke/allgemeines-regelwerk')} width='6vw' height='2.5vw' borderRadius='.2vw' color='#fff' background='transparent' border='none' outline='none' fontSize='1vw' fontWeight='700' _hover={{ backgroundColor: '#1a1a1a' }}>
            Regeln
            <FaExternalLinkAlt style={{ position: "relative", left: "0.25vw", top: "-0.3vw", fontSize: '0.8vw', paddingLeft: "0.1vw" }} />
        </Button>
        <Button onClick={() => handleLinkClick('wiki.newchapter-roleplay.de/docs/guides/willkommen')} width='6vw' height='2.5vw' borderRadius='.2vw' color='#fff' background='transparent' border='none' outline='none' fontSize='1vw' fontWeight='700' _hover={{ backgroundColor: '#1a1a1a' }}>
            Guide
            <FaExternalLinkAlt style={{ position: "relative", left: "0.25vw", top: "-0.3vw", fontSize: '0.8vw', paddingLeft: "0.1vw" }} />
        </Button>
        {/* <Button width='6vw' height='2.5vw' borderRadius='.2vw' color='#fff' background='transparent' border='none' outline='none' fontSize='1vw' fontWeight='700' _hover={{ backgroundColor: '#1a1a1a' }}>
            Streamer
        </Button> */}
        {/* <Button width='6vw' height='2.5vw' borderRadius='.2vw' color='#fff' background='transparent' border='none' outline='none' fontSize='1vw' fontWeight='700' _hover={{ backgroundColor: '#1a1a1a' }}>
            Spenden
        </Button> */}
        {isLoggedIn ? (
            <Button
                onClick={handleProfileRedirect}
                width="auto"
                height="2.5vw"
                borderRadius=".2vw"
                color="#fff"
                background="transparent"
                border="none"
                outline="none"
                fontSize="1vw"
                fontWeight="700"
                _hover={{ backgroundColor: '#1a1a1a' }}
            >
                <FaUserCircle style={{ fontSize: '1vw', marginRight: '0.5vw' }} />
                {username}
            </Button>
        ) : (
            <Button
                onClick={handleLoginClick}
                width="6vw"
                height="2.5vw"
                borderRadius=".2vw"
                color="#fff"
                background="rgb(51, 153, 255)"
                border="none"
                outline="none"
                fontSize="1vw"
                fontWeight="700"
                _hover={{ backgroundColor: '#1a1a1a' }}
            >
                Anmelden
            </Button>
        )}
    </Box>
    );
}

export default Header;