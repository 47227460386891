import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

function FeaturesSection() {

  const handleLinkClick = (link) => {
    window.open(`https://${link}`, '_blank');
  };

  return (
    <Box paddingInline="5vw" paddingTop="5vw">
      <Heading as="h2" fontWeight="800" fontSize="2.6vh" textAlign="left" paddingBottom="5vh" position="relative" top="13vw">
        STARTE DEIN ABENTEUER AUF <font style={{ color: '#3399ff' }}>NEWCHAPTER</font>!
      </Heading>
      <Box
        position="relative"
        borderRadius="1vh"
        border="1px solid hsla(0, 0%, 100%, .1)"
        top="0"
        left="107vh"
        width="35vw"
        height="35vw"
        style={{
          backgroundImage: "url('/assets/gta-img.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
      </Box>
      <Text position="relative" top="-22.5vw" fontWeight="700" fontSize="2vh" width="50vw" whiteSpace="normal">Willkommen auf unserem einzigartigen Server! Tauche ein in eine Welt voller Abenteuer und unvergesslicher Momente. Wir haben eine detaillierte Anleitung vorbereitet, damit du schnell loslegen kannst. Wir freuen uns, dich bald auf <font style={{ color: '#3399ff' }}>NewChapter</font> zu sehen!</Text>
      <Button onClick={() => handleLinkClick('wiki.newchapter-roleplay.de/docs/guides/willkommen')} position="relative" top="-20vw" width="12vw" color="white" variant="outline" size="lg" _hover={{ backgroundColor:"white", color: 'black' }}>
        Zum Guide
      </Button>
      <Text position="relative" top="-18vw" fontWeight="700" fontSize="2vh" width="50vw" whiteSpace="normal">Schließe dich noch heute an und werde Teil einer lebendigen Community.</Text>
    </Box>
  );
}

export default FeaturesSection;