import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import { useAuth } from '../provider/AuthProvider';

function AuthModal({ isOpen, onClose }) {
    const [username, setUsername2] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { setIsLoggedIn, setUsername, setUserId } = useAuth();
    const toast = useToast();
    const apiUrl = 'https://newchapter-roleplay.de/api/api-proxy.php';

    const handleSubmitLogin = async () => {
        const sendData = {
            apiaction: 'login',
            username: username,
            password: password
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sendData),
        });

        if (response.ok) {
            const responseText = await response.text();
            const result = await JSON.parse(responseText);
            if (!result.message.includes("Erfolgreich eingeloggt.")) {
                toast({
                    title: 'Fehler',
                    description: result.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            toast({
                title: 'Erfolg',
                description: 'Erfolgreich eingeloggt.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            handleLoginSuccess(result.username, result.userId);
        } else {
            toast({
                title: 'Fehler',
                description: 'Fehler beim Login.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSubmitRegister = async () => {
        const sendData = {
            apiaction: "register",
            username: username,
            password: password,
            confirmPassword: confirmPassword
        }

        if (password !== confirmPassword) {
            toast({
                title: 'Fehler',
                description: 'Die Passwörter stimmen nicht überein.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sendData),
        });

        if (response.ok) {
            const responseText = await response.text();
            const result = await JSON.parse(responseText);
            if (!result.message.includes("Benutzer erfolgreich registriert.")) {
                toast({
                    title: 'Fehler',
                    description: result.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            login(username, password);
        } else {
            toast({
                title: 'Fehler',
                description: 'Fehler bei der Registrierung.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const login = async (username, password) => {
        const sendData = {
            apiaction: "login",
            username: username,
            password: password
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(sendData),
        });


        if (response.ok) {
            const responseText = await response.text();
            const result = await JSON.parse(responseText);
            if (!result.message.includes("Erfolgreich eingeloggt.")) {
                toast({
                    title: 'Fehler',
                    description: result.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            handleLoginSuccess(result.username, result.userId);
        } else {
            toast({
                title: 'Fehler',
                description: 'Fehler beim Login.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleLoginSuccess = (user, id) => {
        localStorage.setItem('userId', id);
        localStorage.setItem('username', user);
        localStorage.setItem('isLoggedIn', 'true');
        setIsLoggedIn(true);
        setUsername(user);
        setUserId(id);
        onClose();
        window.location.reload();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="rgba(0,0,0,0.9)">
                <ModalHeader color="white">Bitte Anmelden oder Registrieren</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs variant="soft-rounded" colorScheme="blue">
                        <TabList width="100%" display="flex" justifyContent="center">
                            <Tab color="white">Login</Tab>
                            <Tab color="white">Registrieren</Tab>
                        </TabList>

                        <TabPanels>
                            {/* Login Tab */}
                            <TabPanel>
                                <FormControl>
                                    <FormLabel>Benutzername</FormLabel>
                                    <Input
                                        placeholder="Benutzername"
                                        value={username}
                                        onChange={(e) => setUsername2(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Passwort</FormLabel>
                                    <Input
                                        type="password"
                                        placeholder="Passwort"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormControl>

                                <ModalFooter>
                                    <Button colorScheme="blue" onClick={handleSubmitLogin}>Anmelden</Button>
                                    <Button variant="ghost" color="white" onClick={onClose}>Abbrechen</Button>
                                </ModalFooter>
                            </TabPanel>

                            {/* Register Tab */}
                            <TabPanel>
                                <FormControl>
                                    <FormLabel>Benutzername</FormLabel>
                                    <Input
                                        placeholder="Benutzername"
                                        value={username}
                                        onChange={(e) => setUsername2(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Passwort</FormLabel>
                                    <Input
                                        type="password"
                                        placeholder="Passwort"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl mt={4}>
                                    <FormLabel>Passwort bestätigen</FormLabel>
                                    <Input
                                        type="password"
                                        placeholder="Passwort bestätigen"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </FormControl>

                                <ModalFooter>
                                    <Button colorScheme="blue" onClick={handleSubmitRegister}>Registrieren</Button>
                                    <Button variant="ghost" color="white" onClick={onClose}>Abbrechen</Button>
                                </ModalFooter>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default AuthModal;